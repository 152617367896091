<template>
  <IAmOverlay :loading="loading">
    <component :is="agenciesData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert
        v-if="agenciesData === undefined || !canViewAgency"
        variant="danger"
        show
        class="px-2 py-1"
      >
        <div class="text-danger">
          {{ $t('agency.errFetchAgency') }}
        </div>
        <div>
          {{ $t('agency.notFundAgencyById_1') }}
          <b-link
            class="alert-link"
            :to="{ name: 'apps-agencies-list'}"
          >
            {{ $t('agency.notFundAgencyById_2') }}
          </b-link>
          {{ $t('agency.notFundAgencyById_3') }}
        </div>
      </b-alert>

      <div
        v-else-if="agenciesData && canViewAgency"
        class="tabs"
      >
        <b-nav pills>
          <b-nav-item
            to="#"
            :active="$route.hash === '#' || $route.hash === ''"
          >
            <div class="d-flex-center gap-1">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('account') }}</span>
            </div>
          </b-nav-item>
          <b-nav-item
            v-if="!agencyIsF1"
            to="#configs"
            :active="$route.hash === '#configs'"
          >
            <div class="d-flex-center gap-1">
              <feather-icon
                icon="ToolIcon"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('agency.configs.title') }}</span>
            </div>
          </b-nav-item>
          <b-nav-item
            to="#employees"
            :active="$route.hash === '#employees'"
          >
            <div class="d-flex-center gap-1">
              <feather-icon
                icon="UsersIcon"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('employees') }}</span>
            </div>
          </b-nav-item>
          <b-nav-item
            to="#manager"
            :active="$route.hash === '#manager'"
          >
            <div class="d-flex-center gap-1">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('agency.manager') }}</span>
            </div>
          </b-nav-item>
          <b-nav-item
            v-if="!isProduction"
            to="#company"
            :active="$route.hash === '#company'"
          >
            <div class="d-flex-center gap-1">
              <IAmIcon
                icon="buildingOutline"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('agency.company.title') }}</span>
            </div>
          </b-nav-item>
          <b-nav-item
            to="#web-config"
            :active="$route.hash === '#web-config'"
          >
            <div class="d-flex-center">
              <IAmIcon
                icon="internet"
                size="16"
              />
              <span class="d-none d-sm-inline">{{ $t('agency.webConfig.title') }}</span>
            </div>
          </b-nav-item>
        </b-nav>
        <div class="tab-content">
          <div
            :class="['tab-pane', { 'active': $route.hash === '#' || $route.hash === '' }]"
            class="p-0"
          >
            <AgenciesEditTabAccount
              :agencies-data="agenciesData"
              :loading-prop.sync="loading"
              @refetchAgency="fetchAgency"
            />
          </div>
          <div
            :class="['tab-pane', { 'active': $route.hash === '#configs' }]"
            class="p-0"
          >
            <AgenciesEditTabConfig
              :agencies-data="agenciesData"
              :loading-prop.sync="loading"
              @refetchAgency="fetchAgency"
            />
          </div>
          <div
            :class="['tab-pane', { 'active': $route.hash === '#employees' }]"
            class="p-0"
          >
            <AgenciesEditTabEmployee
              :agencies-data="agenciesData"
              :loading-prop.sync="loading"
              @refetchAgency="fetchAgency"
            />
          </div>
          <div
            :class="['tab-pane', { 'active': $route.hash === '#manager' }]"
            class="p-0"
          >
            <AgenciesManagerTab :manager-employees="agenciesData?.managers.map(item => item.employee)" />
          </div>
          <div
            :class="['tab-pane', { 'active': $route.hash === '#web-config' }]"
            class="p-0"
          >
            <AgenciesEditWebConfig
              :agency-web-config="agenciesData?.agencyConfig"
              :agency-id="agenciesData?.id"
              @refetchAgency="fetchAgency"
            />
          </div>
          <div
            v-if="!isProduction"
            :class="['tab-pane', { 'active': $route.hash === '#company' }]"
            class="p-0"
          >
            <AgenciesEditCompany
              :agency-company="agenciesData?.company"
              :agency-id="agenciesData?.id"
              @refetchAgency="fetchAgency"
            />
          </div>
        </div>
      </div>
    </component>
  </IAmOverlay>
</template>

<script>
import {
  BCard, BAlert, BLink, BOverlay, BNavItem, BNav,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'

import router from '@/router'
import store from '@/store'
import env from '@/libs/env'

import useAgenciesHandle from '@agencies/useAgenciesHandle'
import agenciesStoreModule from '@agencies/agenciesStoreModule'

export default {
  components: {
    BNav,
    BNavItem,
    BCard,
    BAlert,
    BLink,
    BOverlay,
    AgenciesEditWebConfig: () => import('@agencies/agencies-edit/AgenciesEditWebConfig.vue'),
    AgenciesEditTabAccount: () => import('./AgenciesEditTabAccount.vue'),
    AgenciesManagerTab: () => import('./AgenciesManagerTab.vue'),
    AgenciesEditTabEmployee: () => import('./AgenciesEditTabEmployee.vue'),
    AgenciesEditTabConfig: () => import('./AgenciesEditTabConfig.vue'),
    AgenciesEditCompany: () => import('./AgenciesEditCompany.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  setup() {
    const agenciesData = ref(null)

    const AGENCIES_APP_STORE_MODULE_NAME = 'app-agencies'

    // Register module
    if (!store.hasModule(AGENCIES_APP_STORE_MODULE_NAME)) store.registerModule(AGENCIES_APP_STORE_MODULE_NAME, agenciesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AGENCIES_APP_STORE_MODULE_NAME)) store.unregisterModule(AGENCIES_APP_STORE_MODULE_NAME)
    })
    const loading = ref(true)

    const { getAgenciesById } = useAgenciesHandle()

    function fetchAgency() {
      loading.value = true
      return getAgenciesById(router.currentRoute.params.id)
        .then(res => {
          agenciesData.value = res.data
        }).catch(error => {
          if (error.response.status === 404) {
            agenciesData.value = undefined
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    fetchAgency()

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const agencyIsF2 = computed(() => agenciesData?.value?.parentAgency?.id === 1000000)
    const agencyIsF1 = computed(() => agenciesData?.value?.id === 1000000)
    const agencyIsF3 = computed(() => !agencyIsF2.value && !agencyIsF1.value)
    const meAgencyData = computed(() => store.getters['userStore/getAgencyData'])
    const canViewAgency = computed(() => agenciesData?.value?.parentAgency?.id === meAgencyData.value?.id || agenciesData?.value?.id === meAgencyData.value?.id || isRoleF1.value)
    return {
      agenciesData,
      loading,
      fetchAgency,
      canViewAgency,
      isRoleF1,
      isRoleF2,
      isRoleF3,
      agencyIsF1,
      agencyIsF2,
      agencyIsF3,
      isProduction: env.isProduction,
    }
  },
}
</script>
